html {
  height: 100%;
}

body {
  height: inherit;
  margin: 0;
}

.loadingDiv {
  /* position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  align-items: center;
  display: block;
  margin: 0 auto; */

  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: white;
}

.errorText {
  color: red;
}
