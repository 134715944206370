.playerName {
  /* text-decoration: underline; */
  color: #0645ad;
  cursor: pointer;
}

.playerNameDiv {
  white-space: nowrap;
  padding-right: 8px;
}

.playerInfoComponentGrid {
  display: grid;
  grid-template-columns: auto auto;
}

.playerInfoLabel {
  font-weight: bold;
}

.opponentList {
  display: grid;
  grid-template-columns: min-content auto;
}

.deckList {
  white-space: pre-line;
}
